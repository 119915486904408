<template>
  <div>
    <div class="prose prose-md md:prose-lg">
      <h1>Lifestyle Spending Account (LSA)</h1>
      <!-- <h3>
        The 2024 limits for contributions are $4,150 for individuals and $8,300
        for families.
      </h3> -->

      <p>
        Lifestyle Spending Accounts (LSAs) present a compelling option for
        employers seeking to bolster their workforce's well-being. LSAs, while
        subject to varying configurations, typically provide employees with a
        predetermined sum for designated expenditures. This might encompass
        taxable reimbursements for activities promoting physical wellness, such
        as gym memberships, fitness-related gadgets, or even financial wellness
        undertakings like estate planning costs and financial advisor fees.
      </p>
    </div>
    <InfoDropdown title="What is a lifestyle spending account?" :headerLvl="2">
      <p>
        The Rocky Mountain Reserve Lifestyle Spending Account gives you the
        flexibility to create personalized benefit programs for employees. A
        convenient debit card makes it easy for them to spend on what matters
        most.
      </p>
      <p>
        LSAs are a creative way to build a supportive culture and to attract,
        engage, and retain top talent. With a wide range of spending categories,
        from home office goods to fitness to education, or even pet care, you
        can offer a benefit that is tailored to your unique workforce and
        business needs.
      </p>
    </InfoDropdown>

    <InfoDropdown
      title="What are the benefits of offering a lifestyle spending account to your employees?"
      :headerLvl="2"
    >
      <p>
        You are most likely considering a lifestyle spending account because you
        are looking for additional benefit options for a changing and varied
        workforce. Offering a lifestyle spending account is a way for you to
        reinforce and promote your company culture, meet the market demand and
        round out your benefits package with an account that delivers
        personalized spending opportunities for the things that matter most to
        employees.
      </p>
    </InfoDropdown>

    <InfoDropdown
      title="How does a lifestyle spending account work?"
      :headerLvl="2"
    >
      <p>
        Lifestyle spending accounts are a way for you to offer yearly, monthly,
        or other periodic post-tax dollars to your employees to use for spending
        beyond traditional consumer directed healthcare (CDH) accounts. You
        designate the amount you want to contribute per employee and determine
        any limits you would like to set for specific service types within the
        available spending categories. Your employees can then access the funds
        in the form of a convenient debit card.
      </p>
    </InfoDropdown>

    <InfoDropdown title="Employer Resources" :headerLvl="2">
      <ul>
        <li>
          <a
            href="https://rmr.zendesk.com/hc/en-us/articles/13444362576147-Exploring-the-Tax-Implications-of-Lifestyle-Spending-Accounts-LSA-for-Employers"
            >Exploring the Tax Implications of Lifestyle Spending Accounts (LSA)
            for Employers</a
          >
        </li>
      </ul>
    </InfoDropdown>
  </div>
</template>

<script>
import InfoDropdown from "@/components/ui/InfoDropdown.vue";

export default {
  components: {
    InfoDropdown: InfoDropdown
  }
};
</script>
